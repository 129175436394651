<!-- 用户编辑弹窗 -->
<template>
  <el-dialog
    :title="'批量设置时间'"
    :visible="visible"
    width="60%"
    :destroy-on-close="true"
    :lock-scroll="false"
    custom-class="ele-dialog-form"
    @update:visible="updateVisible">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="答题时长" prop="answer_time">
        <el-col :span="10">
          <el-input v-model="ruleForm.answer_time" placeholder="请输入答题时长" oninput = "value=value.replace(/[^\d.]/g,'')"></el-input>
          <span style="color: red">*单位：秒[处理所有数据的答题时长]</span>
        </el-col>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button
        @click="resetForm">取消
      </el-button>
      <el-button
        type="primary"
        @click="save"
        :loading="loading">保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 父组件方法，关闭弹框
    openEditTime: Function
  },
  components: {},
  data() {
    return {
      // 表单数据
      init: {},
      fileList:[],
      // 表单验证规则domains
      ruleForm: Object.assign({
        code: 'readactual',
        answer_time: '',
      }, this.data),
      rules: {
        answer_time: [
          {required: true, message: '请填写时间', trigger: 'blur'}
        ],
      },
      // 提交状态
      loading: false,
      // 角色列表
      roleList: [],

    }
  },
  //mounted
  watch: {
    //deep: true,
    data() {

    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http['post']('/Admin/editTime', this.ruleForm).then(res => {
            this.loading = false;
            if (res.data.code === 200) {
              this.$message({type: 'success', message: res.data.msg});
              if (!this.isUpdate) {
                this.ruleForm = {};
                this.fileList = [];
              }
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    // 关闭
    resetForm(ruleForm){;
      // 调用父组件方法 隐藏弹框
      this.openEditTime(null)
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    // 取消
    closeDialog() {
      this.fileList = [] //清空选择的文件列表
      this.$emit('close', false)
    },

  }
}
</script>

<style scoped>
</style>
